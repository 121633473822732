import { useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { routes } from "../../routes";

const RequireAuth = ({ allowedRoles }) => {
  const auth = JSON.parse(localStorage.getItem("data"));
  const location = useLocation();

  return auth?.user ? (
    <Outlet />
  ) : (
    <Navigate to={routes.home} state={{ from: location }} replace />
  );
};

export default RequireAuth;
