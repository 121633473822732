import React from "react";
import { FaEdit } from "react-icons/fa";

const EditBtn = ({ handleClick, title }) => {
  return (
    <div className="edit-btn" onClick={handleClick}>
      <FaEdit title={title ? title :""} />
    </div>
  );
};

export default EditBtn;
