import "./styles/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "./ScrollToTop";
import "./config/axios/config";
import { DeniedLogin, RequireAuth, UpdatePassword } from "./gates";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthLayout, DashboardLayout } from "./layouts";
import { Login, PasswordReset, NotFound, Unauthorized } from "./pages";
import { routes } from "./routes";
import { lazy, Suspense } from "react";
import { MySpinner } from "./components";

//const Dashboard = lazy(()=> import "./pages/Dashboard")
const Customer = lazy(() => import("./pages/protected/Customer"));
const Inbox = lazy(() => import("./pages/protected/Inbox"));
const Dashboard = lazy(() => import("./pages/protected/Dashboard"));
const Profile = lazy(() => import("./pages/protected/Profile"));
const Command = lazy(() => import("./pages/protected/Command"));
const Plan = lazy(() => import("./pages/protected/Plan"));
const Project = lazy(() => import("./pages/protected/Project"));
const Type = lazy(() => import("./pages/protected/Type"));
const Payment = lazy(() => import("./pages/protected/Payment"));
const Testimony = lazy(() => import("./pages/protected/Testimony"));

function App() {
  return (
    <Suspense fallback={<MySpinner active={true} />}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path={routes.unauthorized} element={<Unauthorized />} />
          <Route element={<AuthLayout />}>
            <Route element={<DeniedLogin />}>
              <Route path={routes.home} element={<Login />} />
              <Route path={routes.reset} element={<PasswordReset />} />
            </Route>
          </Route>

          {/*   <Route path={routes.online} element={<Online />} />
        <Route path={routes.onlineClass} element={<ClassCall />} /> */}
          <Route element={<RequireAuth />}>
            <Route element={<DashboardLayout />}>
              <Route path={routes.dashboard} element={<Dashboard />} />
              <Route path={routes.messages} element={<Inbox />} />
              <Route path={routes.customers} element={<Customer />} />
              <Route path={routes.plans} element={<Plan />} />
              <Route path={routes.payments} element={<Payment />} />
              <Route path={routes.commands} element={<Command />} />
              <Route path={routes.type} element={<Type />} />
              <Route path={routes.projects} element={<Project />} />
              <Route path={routes.testimony} element={<Testimony />} />
              {/*             <Route path={routes.request} element={<Inquiry />} />
            <Route path={routes.teacher} element={<Teacher />} />
            <Route path={routes.subject} element={<Subject />} />
            <Route path={routes.note} element={<Note />} />
            <Route path={routes.class} element={<Classroom />} />
            <Route path={routes.cohort} element={<Cohort />} />
            <Route path={routes.classStudent} element={<ClassroomStudent />} />
            <Route path={routes.student} element={<Student />} />
            <Route path={routes.thesis} element={<Thesis />} />
          */}
              <Route path={routes.profile} element={<Profile />} />
            </Route>

            {/*  <Route path={routes.user} element={<User />} />
          <Route path={routes.staff} element={<Staff />} />

          <Route path={routes.qcm} element={<QCM />} />
          <Route path={routes.site} element={<Site />} />
          <Route path={routes.siteClass} element={<SiteClassroom />} />
          <Route path={routes.cohortClass} element={<ClassroomCohort />} /> */}
          </Route>
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
