export const validEmail = (value) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );
};

export const phone = (value) => {
  return  /^([0]{1}|\+?[234]{3})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g.test(value);
};

export const validPassword = (value) => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&-+=()!]).{8,24}$/.test(
    value
  );
  /* return /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(value); */
};

export const hasNumber = (value) => {
  return /^[0-9]+$/.test(value);
};

export const hasUpperCase = (value) => {
  return /^([A-Z]+)$/.test(value);
};

export const hasLowerCase = (value) => {
  return /^[a-z]+$/.test(value);
};

export const hasSpecial = (value) => {
  return /^[@#$%^&-+=()]+$/.test(value);
};

export const onlyLetters = (value) => {
  return true;
  //return /^[a-zA-Z]+$/.test(value);
};

export const onlyLettersWithSpace = (value) => {
  return true;
 // return /^[a-zA-Z' ]*$/.test(value);
};

export const validateUrl = (value) => {
  //return ^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$
  return /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
    value
  );
};

export const schoolYear = (value) => {
  return /^(?=.*[0-9])(?=.*[-]).{9}$/.test(value);
};

export const mentionNote = (value) => {
  return /^(?=.*[0-9])(?=.*[-]).{5}$/.test(value);
};

// this will help us generate random string for room name
export const generateString = (classroom) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let result = " ";
  const charactersLength = characters.length;
  for (let i = 0; i < 6; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return (
    classroom?.name?.slice(0, 3) +
    "-" +
    result.trim() +
    "-" +
    classroom?.code?.slice(0, 3)
  );

  /*   return result.trim();
   */
};

export const generateCode = (name) => {
  let pass = ""; //remember to declare $pass as an array
  //put the length -1 in cache
  for (let i = 0; i < 4; i++) {
    pass += Math.floor(Math.random() * 4);
  }
  return name.substring(0, 3) + "-" + pass;
};

export const formatUrl = (value) => {
  return value.replace(/\s+/g, "-").toLowerCase();
};

export const getURLParameters = (search) => {
  const params = {};
  const queryString = search.substring(1);

  if (queryString) {
    const keyValuePairs = queryString.split("&");

    keyValuePairs.forEach((keyValuePair) => {
      const [key, value] = keyValuePair.split("=");
      params[key] = decodeURIComponent(value);
    });
  }

  return params;
};
