import React from "react";

const SelectComponent = ({
  handleInput,
  label,
  name,
  required,
  options,
  disabled,
  defaultCheck,
}) => {
  return (
    <div className="select-ctn">
      {/* {label && (<label htmlFor={name}>{label}</label>)} */}
      <select
        name={name}
        id={name}
        onChange={handleInput}
        required={required ? required : false}
        disabled={disabled ? disabled : false}
      >
        {!defaultCheck && <option>Choisir un(e) {label}</option>}
        {options.map((option, index) => (
          <option
            key={index}
            value={option.id}
            defaultValue={
              defaultCheck && parseInt(option.id) === parseInt(defaultCheck)
            }
          >
            {option.value}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectComponent;
