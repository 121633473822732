import React from "react";
import {FaTrash } from "react-icons/fa";

const DeleteBtn = ({ handleClick, title }) => {
  return (
    <div className="delete-btn" onClick={handleClick}>
      <FaTrash title={title ? title :""} />
    </div>
  );
};

export default DeleteBtn;
