import React, { useRef } from "react";

const InputFileComponent = ({
  handleInput,
  label,
  name,
  required,
  error,
  disabled,
  accept,
  multiple,
  files,
}) => {
  const inputRef = useRef();
  const trigerInput = () => {
    inputRef.current.click();
  };

  return (
    <div className="input-file-ctn">
      <label htmlFor={name}>{label}</label>
      <div className="input" onClick={trigerInput}>
        <span>
          {!multiple
            ? "Ajouter un fichier"
            : "Ajouter un ou plusieurs fichiers"}
        </span>
        <input
          type="file"
          id={name}
          accept={accept ? accept : "*"}
          onChange={handleInput}
          required={required ? required : false}
          disabled={disabled ? disabled : false}
          style={error ? { borderColor: "red" } : {}}
          multiple={multiple ? multiple : false}
          ref={inputRef}
        />
      </div>
      <div>
        {files && files.length > 0
          ? files.map((file, index) => (
              <div
                key={index}
                className="badge bg-success"
                style={{
                  whiteSpace: "wrap",
                  marginTop: "15px",
                }}
              >
                {file?.name}
              </div>
            ))
          : ""}
      </div>
      <div className="errors">{error}</div>
    </div>
  );
};

export default InputFileComponent;
