import React, { useState } from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import {
  FaBell,
  FaCamera,
  FaChartPie,
  FaClock,
  FaCrown,
  FaPen,
  FaPowerOff,
  FaQuestion,
  FaTable,
  FaUser,
  FaUserFriends,
  FaUserGraduate,
  FaUsers,
  FaBullhorn,
  FaChalkboardTeacher,
  FaLeanpub,
  FaFileExport,
  FaLocationArrow,
  FaHourglassStart,
  FaVideo,
  FaCommentAlt,
  FaHourglass,
  FaDollarSign,
} from "react-icons/fa";
/* import { FaMapLocationDot } from "react-icons/fa6";
 */ import { Link, NavLink } from "react-router-dom";
import { routes } from "../../../routes";
import AuthService from "../../../services/AuthService";
import { Modal } from "react-bootstrap";
import { InputFileComponent, SubmitBtn } from "../../";
import { ToastContainer, toast } from "react-toastify";
import UserService from "../../../services/UserService";

const DashboardNav = () => {
  const userData = JSON.parse(localStorage.getItem("data"));
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  useState(false);
  const formData = new FormData();

  const handleCloseLogoutModal = () => setShowLogoutModal(false);
  const handleShowLogoutModal = () => setShowLogoutModal(true);

  const userLogout = (e) => {
    setLoading(true);
    e.preventDefault();
    AuthService.logout();
    setLoading(false);
  };

  return (
    <div className="dashboard-nav-container">
      <ToastContainer />

      <Modal show={showLogoutModal} onHide={handleCloseLogoutModal}>
        <Modal.Header closeButton>
          <Modal.Title>Déconnexion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="pass-form-content" onSubmit={userLogout}>
            <div
              className="text-center"
              style={{
                fontSize: "20px",
                lineHeight: "30px",
                paddingBottom: "15px",
              }}
            >
              Vous êtes sûr de vouloir vous déconnecter ?
            </div>
            <div className="d-flex justify-content-center">
              <div>
                <SubmitBtn
                  title="Se déconnecter"
                  isLoading={isLoading}
                  color={"red"}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <>
          <Navbar
            collapseOnSelect={true}
            key={false}
            expand={false}
            className="bg-body-tertiary mb-3 rs-sidebar"
          >
            <Container fluid>
              {/*             <Navbar.Brand href="#">Navbar Offcanvas</Navbar.Brand>
               */}{" "}
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${false}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${false}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
                placement="start"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${false}`}>
                    {process.env.REACT_APP_NAME}
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="dashboard-sidenav-container">
                    <div className="logo">
                      <img
                        src="/assets/images/logo.jpeg"
                        alt="logo"
                      />
                    </div>
                    <div className="user-section">
                      <div className="user-img">
                        <img src="/assets/images/avatar/14.jpg" alt="profile" />
                        {/*  <span
                          className="camera"
                          onClick={handleShowUpdateProfilePictureModal}
                        >
                          <FaCamera />
                        </span> */}

                        {/* <img src="/assets/images/illust.jpeg" alt="profile" /> */}
                      </div>
                      <Link to={routes.profile}>
                        {" "}
                        <div className="user-name">
                          {userData?.user?.username}
                        </div>
                      </Link>
                      {/* <div className="user-title">
                        {userData.user.role == 0
                          ? "Administration"
                          : userData.user.role == 1
                          ? "Enseignant(e)"
                          : userData.user.role == 2
                          ? "Apprenant(e)"
                          : "Admin"}
                      </div> */}
                    </div>
                    <div className="nav-section">
                      <Nav>
                        <ul>
                          <NavLink
                            to={routes.dashboard}
                            className={(nav) =>
                              nav.isActive ? "nav-active" : ""
                            }
                          >
                            <li>
                              {" "}
                              <FaChartPie /> <span>Tableau de board</span>
                            </li>
                          </NavLink>
                          <NavLink
                            to={routes.customers}
                            className={(nav) =>
                              nav.isActive ? "nav-active" : ""
                            }
                          >
                            <li>
                              {" "}
                              <FaUserFriends /> <span>Clients</span>
                            </li>
                          </NavLink>
                          <NavLink
                            to={routes.commands}
                            className={(nav) =>
                              nav.isActive ? "nav-active" : ""
                            }
                          >
                            <li>
                              {" "}
                              <FaHourglass /> <span>Commandes</span>
                            </li>
                          </NavLink>
                          <NavLink
                            to={routes.payments}
                            className={(nav) =>
                              nav.isActive ? "nav-active" : ""
                            }
                          >
                            <li>
                              {" "}
                              <FaDollarSign /> <span>Vente</span>
                            </li>
                          </NavLink>

                          <NavLink
                            to={routes.messages}
                            className={(nav) =>
                              nav.isActive ? "nav-active" : ""
                            }
                          >
                            <li>
                              {" "}
                              <FaCommentAlt /> <span>Messages</span>
                            </li>
                          </NavLink>

                          {
                            <NavLink
                              to={routes.type}
                              className={(nav) =>
                                nav.isActive ? "nav-active" : ""
                              }
                            >
                              <li>
                                {" "}
                                <FaLeanpub /> <span>Types</span>
                              </li>
                            </NavLink>
                          }
                          {
                            <NavLink
                              to={routes.plans}
                              className={(nav) =>
                                nav.isActive ? "nav-active" : ""
                              }
                            >
                              <li>
                                {" "}
                                <FaLeanpub /> <span>Plans</span>
                              </li>
                            </NavLink>
                          }

                          <NavLink
                            to={routes.projects}
                            className={(nav) =>
                              nav.isActive ? "nav-active" : ""
                            }
                          >
                            <li>
                              {" "}
                              <FaTable /> <span>Projets</span>
                            </li>
                          </NavLink>

                          <li
                            onClick={handleShowLogoutModal}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            <FaPowerOff /> <span>Déconnexion</span>
                          </li>
                          {/* </NavLink> */}
                        </ul>
                      </Nav>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </>
        <div className="dashboard-nav">
          {userData?.user?.role == 0 ||
            (userData?.user?.role == 1 && (
              <Link
                to={routes.enterRoom}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="dashboard-nav-icon">
                  <FaVideo />
                  <span className="">Lancer un cours</span>
                </div>
              </Link>
            ))}
          {/* <div className="dashboard-user">
          <span className="dashboard-user-name">
            {" "}
            {userData?.user?.firstname + " " + userData?.user?.lastname}
          </span>{" "}
          <FaUserAlt />
        </div> */}
          <div className="notif">
            <FaBell />
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardNav;
