import { useLocation, Navigate, Outlet } from "react-router-dom";
import { routes } from "../../routes";

const DeniedLoginAccess = () => {
  const auth = JSON.parse(localStorage.getItem("data"));
  const location = useLocation();

  return auth?.user ? (
    <Navigate to={routes.dashboard} state={{ from: location }} replace={true} />
  ) : (
    <Outlet />
  );
};

export default DeniedLoginAccess;
