import React, { useState } from "react";
import {
  FaCamera,
  FaChartPie,
  FaClock,
  FaCommentAlt,
  FaCrown,
  FaPen,
  FaPowerOff,
  FaQuestion,
  FaTable,
  FaUser,
  FaUserFriends,
  FaUserGraduate,
  FaUsers,
  FaUsersCog,
  FaBullhorn,
  FaUserCheck,
  FaGraduationCap,
  FaChalkboardTeacher,
  FaLeanpub,
  FaFileExport,
  FaLocationArrow,
  FaHourglass,
  FaHourglassStart,
  FaDollarSign,
  FaList,
} from "react-icons/fa";
/* import { FaMapLocationDot } from "react-icons/fa6";
 */ import { Link, NavLink } from "react-router-dom";
import { routes } from "../../../routes";
import AuthService from "../../../services/AuthService";
import { Modal } from "react-bootstrap";
import { InputFileComponent, SubmitBtn } from "../../";
import { ToastContainer, toast } from "react-toastify";
import UserService from "../../../services/UserService";

const DashboardSidebar = () => {
  const userData = JSON.parse(localStorage.getItem("data"));
  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState("");
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleCloseLogoutModal = () => setShowLogoutModal(false);
  const handleShowLogoutModal = () => setShowLogoutModal(true);

  const userLogout = (e) => {
    setLoading(true);
    e.preventDefault();
    AuthService.logout();
    setLoading(false);
  };

  return (
    <div className="dashboard-sidebar-container">
      <ToastContainer />

      <Modal show={showLogoutModal} onHide={handleCloseLogoutModal}>
        <Modal.Header closeButton>
          <Modal.Title>Déconnexion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="pass-form-content" onSubmit={userLogout}>
            <div
              className="text-center"
              style={{
                fontSize: "20px",
                lineHeight: "30px",
                paddingBottom: "15px",
              }}
            >
              Vous êtes sûr de vouloir vous déconnecter ?
            </div>
            <div className="d-flex justify-content-center">
              <div>
                <SubmitBtn
                  title="Se déconnecter"
                  isLoading={isLoading}
                  color={"red"}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div className="logo">
        <img src="/assets/images/logo.jpeg" alt="logo" />
      </div>
      <div className="user-section">
        <div className="user-img">
          <img src="/assets/images/avatar/14.jpg" alt="profile" />

          {/* <img src="/assets/images/illust.jpeg" alt="profile" /> */}
        </div>
        <Link to={routes.profile}>
          {" "}
          <div className="user-name">{userData?.user?.username}</div>
        </Link>
        {/* <div className="user-title">
          {userData.user.role == 0
            ? "Administration"
            : userData.user.role == 1
            ? "Enseignant(e)"
            : userData.user.role == 2
            ? "Apprenant(e)"
            : "Admin"}
        </div> */}
      </div>
      <div className="nav-section">
        <ul>
          <NavLink
            to={routes.dashboard}
            className={(nav) => (nav.isActive ? "nav-active" : "")}
          >
            <li>
              {" "}
              <FaChartPie /> <span>Tableau de board</span>
            </li>
          </NavLink>
          <NavLink
            to={routes.customers}
            className={(nav) => (nav.isActive ? "nav-active" : "")}
          >
            <li>
              {" "}
              <FaUserFriends /> <span>Clients</span>
            </li>
          </NavLink>
          <NavLink
            to={routes.commands}
            className={(nav) => (nav.isActive ? "nav-active" : "")}
          >
            <li>
              {" "}
              <FaHourglass /> <span>Commandes</span>
            </li>
          </NavLink>
          <NavLink
            to={routes.payments}
            className={(nav) => (nav.isActive ? "nav-active" : "")}
          >
            <li>
              {" "}
              <FaDollarSign /> <span>Vente</span>
            </li>
          </NavLink>
          <NavLink
            to={routes.testimony}
            className={(nav) => (nav.isActive ? "nav-active" : "")}
          >
            <li>
              {" "}
              <FaList /> <span>Témoignages</span>
            </li>
          </NavLink>
          
          <NavLink
            to={routes.messages}
            className={(nav) => (nav.isActive ? "nav-active" : "")}
          >
            <li>
              {" "}
              <FaCommentAlt /> <span>Messages</span>
            </li>
          </NavLink>

          {
            <NavLink
              to={routes.type}
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              <li>
                {" "}
                <FaLeanpub /> <span>Types</span>
              </li>
            </NavLink>
          }
          {
            <NavLink
              to={routes.plans}
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              <li>
                {" "}
                <FaLeanpub /> <span>Plans</span>
              </li>
            </NavLink>
          }

          <NavLink
            to={routes.projects}
            className={(nav) => (nav.isActive ? "nav-active" : "")}
          >
            <li>
              {" "}
              <FaTable /> <span>Projets</span>
            </li>
          </NavLink>
         
          <li onClick={handleShowLogoutModal} style={{ cursor: "pointer" }}>
            {" "}
            <FaPowerOff /> <span>Déconnexion</span>
          </li>
          {/* </NavLink> */}
        </ul>
      </div>
    </div>
  );
};

export default DashboardSidebar;
