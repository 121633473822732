import React, { useEffect } from "react";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate(routes.dashboard);
    }, 5000);
  }, []);
  return (
    <div className="error-container">
      <div className="b-container">
        <div className="error-wraper">
          <div className="error-img">
            <img src="./assets/images/SVG/hand-perfect2.svg" alt="goodbye" />
          </div>
          <div className="error">Page non trouvée ! </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
