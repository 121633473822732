import React, { useState } from "react";
import { useLayoutEffect } from "react";
import { Outlet } from "react-router-dom";
import { DashboardNav, DashboardSidebar } from "../../components";

const DashboardLayout = () => {
  useLayoutEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    //document.getElementById("root").style.overflow = "hidden";
  }, []);
  return (
    <div className="dashboard-layout-container">
      <DashboardSidebar />
      <main>
        <DashboardNav />
        <Outlet />
      </main>
    </div>
  );
};

export default DashboardLayout;
