import React from "react";

const TextareaComponent = ({
  handleInput,
  label,
  name,
  required,
  defaultValue,
  error,
}) => {
  return (
    <div className="textarea-ctn">
      <label htmlFor={name}>{label}</label>
      <textarea
        id={name}
        defaultValue={defaultValue}
        onChange={handleInput}
        required={required ? required : false}
        cols="10"
        rows="5"
        style={error ? { borderColor: "red" } : {}}
      ></textarea>
      <div className="errors">{error}</div>
    </div>
  );
};

export default TextareaComponent;
