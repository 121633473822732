import axios from "axios";
class AuthService {
  login(data) {
    return axios.post(`/auth/login`, data);
  }

  changePassword(id, data) {
    return axios.post(`/auth/password/change/${id}`, data);
  }

  logout() {
    localStorage.clear();
    window.location.reload();
    axios.post(`/auth/logout`).then((res) => {});
  }
}

export default new AuthService();
