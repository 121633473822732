import axios from "axios";
class UserService {
  getAll() {
    return axios.get(`/users`);
  }
  get(id) {
    return axios.get(`/users/${id}`);
  }
  create(data) {
    return axios.post(`/users`, data);
  } //users/password/update/{id}
  update(id, data) {
    return axios.put(`/users/${id}`, data);
  }
  changeUserRole(id, data) {
    return axios.post(`/users/role/update/${id}`, data);
  }
  getCurrentAuthUser() {
    return axios.get(`/users/current`);
  }
  changeUserPassword(id, data) {
    return axios.post(`/users/password/update/${id}`, data);
  }
  changeUserProfilePicture(id, data) {
    return axios.post(`/users/profile/picture/update/${id}`, data);
  }
  delete(id) {
    return axios.delete(`/users/${id}`);
  }
  restore(id) {
    return axios.post(`/users_restore/${id}`);
  }
  // deleteAll() {
  //     return http.delete(`/requesting`);
  // }
  // findByTitle(titre) {
  //     return http.get(`/requesting?titre=${titre}`);
  // }
}
export default new UserService();
