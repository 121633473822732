import React from "react";

const MyBtn = ({ title, handleClick }) => {
  return (
    <div className="submit-btn" onClick={handleClick}>
      <button>{title} </button>
    </div>
  );
};

export default MyBtn;
