export const routes = {
  notFound: "*",
  unauthorized: "/acces-denied",
  home: "/",
  login: "/auth/login",
  register: "/auth/register",
  reset: "/auth/password/reset",
  user: "/user",
  customers: "/customers",
  plans: "/plans",
  projects: "/projects",
  commands: "/commands",
  messages: "/messages",
  payments: "/payments",
  type:"/types",
  dashboard:"/dashboard",
  testimony:"/testimonies"
};
