import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  FaBirthdayCake,
  FaEnvelope,
  FaFlag,
  FaGraduationCap,
  FaPhoneSquareAlt,
  FaRegUser,
  FaRegUserCircle,
  FaThumbtack,
  FaUserAlt,
  FaUserLock,
  FaUserShield,
  FaUserTie,
  FaEdit,
} from "react-icons/fa";
import UserService from "../../services/UserService";
import {
  InputComponent,
  MyBtn,
  MySpinner,
  PasswordComponent,
  SearchInput,
  SubmitBtn,
} from "../../components";
import {
  onlyLettersWithSpace,
  validEmail,
  hasNumber,
  validPassword,
} from "../../helpers/validation";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import AuthService from "../../services/AuthService";

const Profile = () => {
  const userData = JSON.parse(localStorage.getItem("data"));
  const [user, setUser] = useState(null);
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
  const [showUpdateProfilModal, setShowUpdateProfilModal] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [birthday, setBirthday] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [firstnameError, setFirstnameError] = useState("");
  const [lastnameError, setLastnameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [birthdayError, setBirthdayError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [newPasswordConfirmationError, setNewPasswordConfirmationError] =
    useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    UserService.get(userData?.user?.id).then((res) => {
      setUser(res.data.user);
      setFirstname(res.data.user.firstname);
      setLastname(res.data.user.lastname);
      setEmail(res.data.user.email);
      setPhone(res.data.user.phone);
      setAddress(res.data.user.address);
      setBirthday(res.data.user.birthday);
      setLoading(false)
    });
    if (userData?.user?.fresh) {
      setShowUpdatePasswordModal(true);
    }
  }, [showUpdatePasswordModal, showUpdateProfilModal]);

  const handleFirstname = (e) => {
    setFirstnameError("");
    setFirstname(e.target.value);
    if (!onlyLettersWithSpace(e.target.value)) {
      setFirstnameError("Le prénom doit contenir uniquement des lettres");
    }
    if (e.target.value.length < 3) {
      setFirstnameError("Prénom trop court");
    }
  };
  const handleLastname = (e) => {
    setLastnameError("");
    setLastname(e.target.value);
    if (!onlyLettersWithSpace(e.target.value)) {
      setLastnameError("Le nom doit contenir uniquement des lettres");
    }
    if (e.target.value.length < 3) {
      setLastnameError("Nom trop court");
    }
  };
  const handleEmail = (e) => {
    setEmailError("");
    setEmail(e.target.value);
    if (!validEmail(e.target.value)) {
      setEmailError("Email non correct");
    }
  };
  const handleAddress = (e) => {
    setAddressError("");
    setAddress(e.target.value);
    if (e.target.value.length < 3) {
      setAddressError("Adresse trope courte");
    }
  };
  const handlePhone = (e) => {
    setPhoneError("");
    setPhone(e.target.value);
    if (!hasNumber(e.target.value)) {
      setPhoneError(
        "Le numero de téléphone doit contenir uniquement des chiffres"
      );
    }
  };
  const handleBirthday = (e) => {
    setBirthdayError("");
    setBirthday(e.target.value);
    let year = e.target.value.split("-")[0];
    const today = new Date().getFullYear();
    if (today - year < 2) {
      setBirthdayError("Date de naissance trop courte ou invalide");
    }
  };

  const handleOldPassword = (e) => {
    setOldPassword(e.target.value);
  };

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
    setNewPasswordError("");
    if (e.target.value.length < 8) {
      setNewPasswordError(
        "Le mot de passe doit contenir au moins 8 caractères"
      );
    }
    if (!validPassword(e.target.value)) {
      setNewPasswordError(
        "Le mot de passe doit contenir au moins une lettre majusule, une lettre minuscule et un caractère spécial"
      );
    }
  };

  const handleNewPasswordConfirmation = (e) => {
    setNewPasswordConfirmationError("");
    setNewPasswordConfirmation(e.target.value);
    if (newPassword !== e.target.value) {
      setNewPasswordConfirmationError("Mots de passes incorrects");
    }
  };

  const handleCloseUpdatePasswordModal = () =>
    setShowUpdatePasswordModal(false);
  const handleShowUpdatePasswordModal = () => setShowUpdatePasswordModal(true);

  const handleCloseUpdateProfilModal = () => setShowUpdateProfilModal(false);
  const handleShowUpdateProfilModal = () => setShowUpdateProfilModal(true);

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!newPassword || !oldPassword || !newPasswordConfirmation) {
      toast.error("Veuillez remplir tous les champs svp !!");
    } else if (newPasswordError || newPasswordConfirmationError) {
      toast.error("Veuillez vérifier les informations svp !!");
    } else {
      const data = {
        oldPassword: oldPassword,
        newPassword: newPassword,
      };
      AuthService.changePassword(user?.id, data)
        .then((res) => {
          if (res.data.error) {
            setLoading(false)
            toast.error("Erreur lors de la modification du mot de passe");
          } else {
            userData.user = res.data.user;
            localStorage.setItem("data", JSON.stringify(userData));
            setShowUpdatePasswordModal(false);
            toast.success("Mot de passe de l'utilisateur modifié avec succès");
          }
        })
        .catch((err) => {
          setLoading(false)
          toast.error("Erreur lors de la mise à ou du mot de passe");
        });
    }
  };

  const handleUpdateUser = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      firstnameError ||
      lastnameError ||
      phoneError ||
      emailError ||
      birthdayError ||
      addressError
    ) {
      toast.error("Veuillez corriger les erreurs svp !!");
      setLoading(false);
    } else if (
      !email ||
      !firstname ||
      !lastname ||
      !phone ||
      !birthday ||
      !address
    ) {
      toast.error("Veuillez remplir tous les champs svp !!");
    } else {
      const data = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        phone: phone,
        address: address,
        birthday: birthday,
      };
      UserService.update(user?.id, data)
        .then((res) => {
          if (res.data.error) {
            toast.error("Erreur lors de la modification du profil");
            setLoading(false)
          } else {
            userData.user = res.data.user;
            localStorage.setItem("data", JSON.stringify(userData));
            toast.success("Profil modifié avec succès");
            setShowUpdateProfilModal(false);
          }
        })
        .catch((err) => {
          toast.error("Erreur");
          setLoading(false);
        });
    }
  };

  return (
    <div className="profile-container">
      <ToastContainer />
      <Modal show={showUpdateProfilModal} onHide={handleCloseUpdateProfilModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier son profil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="pass-form-content" onSubmit={handleUpdateUser}>
            <InputComponent
              name="lastname"
              label="Nom"
              defaultValue={lastname}
              handleInput={handleLastname}
              required={true}
              error={lastnameError}
            />
            <InputComponent
              name="firstname"
              label="Prenom(s)"
              defaultValue={firstname}
              handleInput={handleFirstname}
              required={true}
              error={firstnameError}
            />
            <InputComponent
              name="email"
              type="email"
              label="Email"
              defaultValue={email}
              handleInput={handleEmail}
              required={true}
              error={emailError}
            />
            <InputComponent
              name="phone"
              type="number"
              label="Numero de téléphone"
              defaultValue={phone}
              handleInput={handlePhone}
              required={true}
              error={phoneError}
            />
            <InputComponent
              name="address"
              label="Adresse"
              defaultValue={address}
              handleInput={handleAddress}
              required={true}
              error={addressError}
            />
            <InputComponent
              name="birthday"
              type="date"
              label="Anniversaire"
              defaultValue={birthday}
              handleInput={handleBirthday}
              required={true}
              error={birthdayError}
            />
            <div>
              <SubmitBtn isLoading={isLoading} title="Modifier" />
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showUpdatePasswordModal}
        onHide={handleCloseUpdatePasswordModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modifier son mot de passe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="pass-form-content" onSubmit={handleUpdatePassword}>
            <PasswordComponent
              handlePassword={handleOldPassword}
              title="Ancien mot de passe"
            />
            <PasswordComponent
              handlePassword={handleNewPassword}
              title="Nouveau mot de passe"
              error={newPasswordError}
            />
            <PasswordComponent
              handlePassword={handleNewPasswordConfirmation}
              title="Confirmer le nouveau mot de passe"
              error={newPasswordConfirmationError}
            />

            <div>
              <SubmitBtn
                title="Modifier le mot de passe"
                isLoading={isLoading}
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div className="d-flex align-items-center mb-3 mt-3">
        <MyBtn
          title="Modifier le mot de passe"
          handleClick={handleShowUpdatePasswordModal}
        />

        <div className="m-3 mt-0 mb-0">
          <div className="profile-edit" onClick={handleShowUpdateProfilModal}>
            <span>Editer le profil</span> <FaEdit />
          </div>
        </div>
      </div>
      <div className="profile-wraper">
        <div className="profile-grid-section-container">
          <div className="profile-grid-section">
            <div className="profile-grid-item">
              <div className="profile-grid-item-title"> Nom</div>
              <div className="profile-grid-item-wraper">
                <div className="profile-grid-item-icon">
                  <FaUserAlt />
                </div>
                <div className="profile-grid-item-content">
                  {user?.lastname}
                </div>
              </div>
            </div>
            <div className="profile-grid-item">
              <div className="profile-grid-item-title"> Prénom(s)</div>
              <div className="profile-grid-item-wraper">
                <div className="profile-grid-item-icon">
                  <FaUserAlt />
                </div>
                <div className="profile-grid-item-content">
                  {user?.firstname}
                </div>
              </div>
            </div>
            <div className="profile-grid-item">
              <div className="profile-grid-item-title"> Rôle</div>
              <div className="profile-grid-item-wraper">
                <div className="profile-grid-item-icon">
                  <FaUserShield />
                </div>
                <div className="profile-grid-item-content">
                  {userData.user.role == 0
                    ? "Administration"
                    : userData.user.role == 1
                    ? "Enseignant(e)"
                    : userData.user.role == 2
                    ? "Apprenant(e)"
                    : "Admin"}
                </div>
              </div>
            </div>
            <div className="profile-grid-item">
              <div className="profile-grid-item-title"> Email</div>
              <div className="profile-grid-item-wraper">
                <div className="profile-grid-item-icon">
                  <FaEnvelope />
                </div>
                <div className="profile-grid-item-content">{user?.email}</div>
              </div>
            </div>
            <div className="profile-grid-item">
              <div className="profile-grid-item-title"> Téléphone</div>
              <div className="profile-grid-item-wraper">
                <div className="profile-grid-item-icon">
                  <FaPhoneSquareAlt />
                </div>
                <div className="profile-grid-item-content">{user?.phone}</div>
              </div>
            </div>
            <div className="profile-grid-item">
              <div className="profile-grid-item-title"> Anniversaire</div>
              <div className="profile-grid-item-wraper">
                <div className="profile-grid-item-icon">
                  <FaBirthdayCake />
                </div>
                <div className="profile-grid-item-content">
                  {moment(user?.birthday).format("DD-MM-YYYY")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="profile-address-container">
          <div className="profile-address">
            <div className="profile-grid-item">
              <div className="profile-grid-item-title"> Adresse</div>
              <div className="profile-grid-item-wraper">
                <div className="profile-grid-item-icon">
                  <FaFlag />
                </div>
                <div className="profile-grid-item-content">{user?.address}</div>
              </div>
            </div>
          </div>
        </div>
        {userData?.user?.role === 1 && (
          <div className="profile-grid-section-container">
            <div className="profile-grid-section">
              <div className="profile-grid-item">
                <div className="profile-grid-item-title"> Dénomination</div>
                <div className="profile-grid-item-wraper">
                  <div className="profile-grid-item-icon">
                    <FaThumbtack />
                  </div>
                  <div className="profile-grid-item-content">
                    {user?.teacher?.denomination}
                  </div>
                </div>
              </div>
              <div className="profile-grid-item">
                <div className="profile-grid-item-title"> Niveau d'étude</div>
                <div className="profile-grid-item-wraper">
                  <div className="profile-grid-item-icon">
                    <FaGraduationCap />
                  </div>
                  <div className="profile-grid-item-content">
                    {user?.teacher?.level}
                  </div>
                </div>
              </div>
              <div className="profile-grid-item">
                <div className="profile-grid-item-title"> Date de début</div>
                <div className="profile-grid-item-wraper">
                  <div className="profile-grid-item-icon">
                    <FaUserShield />
                  </div>
                  <div className="profile-grid-item-content">
                    {moment(user?.teacher?.starting_date).format("DD-MM-YYYY")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {userData?.user?.role === 1 && (
          <div className="profile-address-container">
            <div className="profile-address">
              <div className="profile-grid-item">
                <div className="profile-grid-item-title"> Expérience</div>
                <div className="profile-grid-item-wraper">
                  {/* <div className="profile-grid-item-icon">
                <FaFlag />
              </div> */}
                  <div className="profile-grid-item-content">
                    {user?.teacher?.experience}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {userData?.user?.role === 0 && (
          <div className="profile-grid-section-container">
            <div className="profile-grid-section">
              <div className="profile-grid-item">
                <div className="profile-grid-item-title"> Titre</div>
                <div className="profile-grid-item-wraper">
                  <div className="profile-grid-item-icon">
                    <FaUserLock />
                  </div>
                  <div className="profile-grid-item-content">
                    {user?.administration?.title}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {userData?.user?.role === 0 && (
          <div className="profile-address-container">
            <div className="profile-address">
              <div className="profile-grid-item">
                <div className="profile-grid-item-title">
                  {" "}
                  Description du poste
                </div>
                <div className="profile-grid-item-wraper">
                  {/* <div className="profile-grid-item-icon">
                <FaFlag />
              </div> */}
                  <div className="profile-grid-item-content">
                    {user?.administration?.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {userData?.user?.role === 2 && (
          <div className="profile-grid-section-container">
            <div className="profile-grid-section">
              <div className="profile-grid-item">
                <div className="profile-grid-item-title">
                  {" "}
                  Dernier diplôme obtenu
                </div>
                <div className="profile-grid-item-wraper">
                  <div className="profile-grid-item-icon">
                    <FaGraduationCap />
                  </div>
                  <div className="profile-grid-item-content">
                    {user?.student?.last_diploma}
                  </div>
                </div>
              </div>
              <div className="profile-grid-item">
                <div className="profile-grid-item-title"> Pére</div>
                <div className="profile-grid-item-wraper">
                  <div className="profile-grid-item-icon">
                    <FaRegUser />
                  </div>
                  <div className="profile-grid-item-content">
                    {user?.student?.father}
                  </div>
                </div>
              </div>
              <div className="profile-grid-item">
                <div className="profile-grid-item-title"> Mère</div>
                <div className="profile-grid-item-wraper">
                  <div className="profile-grid-item-icon">
                    <FaRegUserCircle />
                  </div>
                  <div className="profile-grid-item-content">
                    {user?.student?.mother}
                  </div>
                </div>
              </div>
              <div className="profile-grid-item">
                <div className="profile-grid-item-title">
                  {" "}
                  Profession du père
                </div>
                <div className="profile-grid-item-wraper">
                  <div className="profile-grid-item-icon">
                    <FaUserTie />
                  </div>
                  <div className="profile-grid-item-content">
                    {user?.student?.father_occupation}
                  </div>
                </div>
              </div>
              <div className="profile-grid-item">
                <div className="profile-grid-item-title">
                  {" "}
                  Profession de la mère
                </div>
                <div className="profile-grid-item-wraper">
                  <div className="profile-grid-item-icon">
                    <FaUserTie />
                  </div>
                  <div className="profile-grid-item-content">
                    {user?.student?.mother_occupation}
                  </div>
                </div>
              </div>
              <div className="profile-grid-item">
                <div className="profile-grid-item-title">
                  {" "}
                  Téléphone du père
                </div>
                <div className="profile-grid-item-wraper">
                  <div className="profile-grid-item-icon">
                    <FaPhoneSquareAlt />
                  </div>
                  <div className="profile-grid-item-content">
                    {user?.student?.father_phone}
                  </div>
                </div>
              </div>
              <div className="profile-grid-item">
                <div className="profile-grid-item-title">
                  {" "}
                  Téléphone de la mère
                </div>
                <div className="profile-grid-item-wraper">
                  <div className="profile-grid-item-icon">
                    <FaPhoneSquareAlt />
                  </div>
                  <div className="profile-grid-item-content">
                    {user?.student?.mother_phone}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
