import React from "react";
import { Outlet } from "react-router-dom";
import { DefaultFooter, DefaultHeader, ResponsiveHeader } from "../../components";

const DefaultLayout = () => {

  const handleScroll = (e) => {
    let hd = document.getElementsByClassName("default-header-container");
    if (window.scrollY >= 80) {
      hd[0].classList.add("bgOnScroll");
      document.getElementsByClassName("default-header-logo")[0].firstChild.src = `${
        process.env.PUBLIC_URL + "/assets/images/levelop-logo.svg"
      }`;
    } else {
      hd[0].classList.remove("bgOnScroll");
      document.getElementsByClassName("default-header-logo")[0].firstChild.src = `${
        process.env.PUBLIC_URL + "/assets/images/levelop-logo-white.svg"
      }`;
    }
  };
  window.addEventListener("scroll", handleScroll);
  return (
    <div className="default-layout-container">
      <header>
        <DefaultHeader/>
        {/* <ResponsiveHeader/> */}
      </header>
      <main>
        <Outlet />
      </main>
      <footer>
        <DefaultFooter/>
      </footer>
    </div>
  );
};

export default DefaultLayout;
